@import "./../../../resources/styles/themes/themify";
@include themify {
  .btn {
    align-self: stretch;
    background-color: map-get($map, color-primary);
    border-radius: 0;
    color: map-get($map, color-primary);
    cursor: pointer;
    font-size: map-get($map, font-size);
    padding: 0 map-get($map, spacing);
    @media screen and (max-width: 768px) {
      margin-left: auto;
      min-width: inherit;
      padding: 0 map-get($map, spacing-half);
    }
  }
  .menu {
    box-shadow: 0 34px 34px 0 map-get($map, dropdown-shadow-color);
    max-width: 95vw;
    min-width: 120px;
    z-index: 999;
    &__paper {
      background-color: map-get($map, page-primary-bg-color);
      border-radius: inherit;
      box-shadow: none;
    }
  }
}
