@import "./../../../resources/styles/themes/themify";
@include themify {
  .list {
    margin-bottom: map-get($map, spacing-double);
    margin-top: map-get($map, spacing-double);
    @media screen and (max-width: map-get($map, screen-sm)) {
      margin-bottom: map-get($map, spacing);
      margin-top: map-get($map, spacing);
    }
    &__title {
      color: map-get($map, font-secondary-color);
      font-size: 24px;
      font-weight: map-get($map, font-weight-strong);
      margin-bottom: map-get($map, spacing-double);
      text-align: center;
      @media screen and (max-width: map-get($map,screen-sm)), print {
        margin-bottom: map-get($map, spacing);
      }
    }
    &__content {
      background-color: map-get($map, page-primary-hover-color);
      border-radius: 8px;
      padding: map-get($map, spacing) map-get($map, spacing-double) * 6;
      @media screen and (max-width: map-get($map, mui-screen-md)) {
        padding-left: map-get($map, spacing-half);
        padding-right: map-get($map, spacing-half);
      }
    }
    &__item {
      padding: map-get($map, spacing-half) 12px;
      text-align: left;
      @media screen and (max-width: map-get($map, mui-screen-md)), print {
        text-align: center;
      }
    }
    &__item-label {
      color: map-get($map, color-gray-dark);
      font-size: map-get($map, font-size-sm);
      line-height: 1.5;
    }
    &__item-value {
      color: map-get($map, font-secondary-color);
      font-size: map-get($map, font-size);
      font-weight: map-get($map, font-weight-strong);
      margin-top: 7px;
      @media screen and (max-width: map-get($map, screen-xs)) {
        font-size: map-get($map, font-size-sm);
      }
    }
  }
}
