$color-gray-dark: #6f7a94;
$color-gray: #717c96;
$color-gray-1: #9298a8;
$color-gray-2: #a8b2c7;
$color-gray-3: #acb2c1;
$color-gray-4: #d7d9e1;
$color-gray-5: #d8d8d8;
$color-gray-light: #ebebef;
$color-gray-light-1: #f4f4f7;
$color-gray-light-2: #f7f9fc;
$color-gray-light-3: #fbfbfc;
$color-gray-light-4: #f7f9fc;
$color-gray-light-5: rgba(188, 198, 218, 0.3);
$color-gray-light-6: rgba(213, 215, 222, 0.72);
$color-gray-light-7: rgba(235, 235, 239, 0.2);
$color-primary: #e6007e;
$color-primary-light: #e85598;
$color-secondary-dark: #383847;
$color-secondary: #444355;
$color-secondary-light: #575666;
$color-secondary-light-1: #686775;
$color-error-light: rgba(227, 7, 44, 0.1);
$color-transparent: rgba(159, 159, 159, 0.15);
$color-transparent-1: rgba(0, 0, 0, 0.15);
$color-white: #fff;
$btcx-logo-filter: contrast(100%) invert(165%) hue-rotate(-188deg)
  brightness(1.5);
