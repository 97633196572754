@import "../../../resources/styles/common/mixins";
@import "../../../resources/styles/themes/themify";
@include themify {
  .wallet-export-content {
    margin: map-get($map, spacing-double) 0;
    &__guid-pwd {
      text-align: center;
    }
    &__guid-pwd-item {
      &:not(:last-child) {
        margin-bottom: map-get($map, spacing);
      }
    }
    &__guid-pwd-label {
      color: map-get($map, font-tertiary-color);
      margin-bottom: map-get($map, spacing-half);
      text-transform: uppercase;
    }
    &__guid-pwd-value {
      align-items: center;
      color: map-get($map, font-secondary-color);
      display: flex;
      font-size: map-get($map, font-size-lg);
      font-weight: map-get($map, font-weight-extra-strong);
      justify-content: center;
      word-break: break-all;
    }
    &__footer {
      @include mobile {
        font-size: map-get($map, font-size-sm);
      }
    }
    &__subtitle {
      color: map-get($map, font-tertiary-color);
      margin-bottom: map-get($map, spacing-half);
      margin-top: map-get($map, spacing-double);
      text-align: center;
    }
    &__link {
      @include link;

      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
