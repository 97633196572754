@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .payment-instruction {
    @include print-style;

    border: 1px dashed map-get($map, color-gray-light);
    border-radius: 3px;
    margin-left: 18px;
    margin-right: 18px;
    padding: map-get($map, spacing-half) map-get($map, spacing-half) / 2;
    text-align: center;
    width: 100%;
    &:not(:first-child) {
      margin-top: map-get($map, spacing-half);
    }
    &.payment-instruction--offset-top {
      margin-top: map-get($map, spacing);
    }
    &-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0 0 0 5px;
      width: calc(100% - 20px);
    }
    &-item {
      width: 100%;
    }
    &-list-item {
      width: 100%;
    }
    &-image-wrapper {
      align-items: flex-end;
      display: inline-flex;
      flex-grow: 1;
      justify-content: center;
      margin-bottom: 150px;
      width: 100%;
      @media screen and (max-width: map-get($map, screen-lg)) {
        align-items: center;
        margin: 10px 0;
      }
    }
    &__label {
      @include print-style;

      color: map-get($map, color-gray-dark);
      font-size: map-get($map, font-size-sm);
      line-height: 1.5;
    }
    &__value {
      @include print-style;

      align-items: center;
      color: map-get($map, font-secondary-color);
      display: inline-flex;
      flex-wrap: wrap;
      font-size: map-get($map, font-size-sm);
      justify-content: center;
      max-width: 100%;
    }
    &__additional-label {
      @include print-style;

      color: map-get($map, color-gray-dark);
      font-size: map-get($map, font-size-xs);
      line-height: 1;
      margin-top: 5px;
    }
  }
}
