@import "../../../../../../resources/styles/common/mixins";
@import "../../../../../../resources/styles/themes/themify";
@include themify {
  .order-details-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__content-wrapper {
      align-items: center;
      border-bottom: 1px solid map-get($map, color-gray-light);
      display: flex;
      justify-items: flex-start;
      padding: map-get($map, spacing) map-get($map, spacing-double)
        map-get($map, spacing) map-get($map, spacing-half);
      @media screen and (max-width: map-get($map, screen-sm)) {
        padding: map-get($map, spacing-half);
        padding-right: map-get($map, spacing-double);
      }
      i {
        @media screen and (max-width: map-get($map, screen-sm)) {
          align-self: flex-start;
          display: inline-flex;
          margin-top: map-get($map, spacing-half);
        }
        &::before {
          @include print-style;

          color: map-get($map, page-primary-link-color);
          @media screen and (max-width: map-get($map, screen-sm)) {
            font-size: 20px;
          }
        }
      }
    }
    &__logo {
      display: none;
      @media print {
        display: inline-block;
      }
    }
    &__feedback {
      @include print-style;

      color: map-get($map, color-gray-dark);
      font-size: map-get($map, font-size);
      font-weight: map-get($map, font-weight-default);
      line-height: 1.5;
      @media screen and (max-width: map-get($map, screen-xs)) {
        font-size: map-get($map, font-size-sm);
      }
    }
    &__head {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: map-get($map, screen-sm)) {
        margin: 3px 0;
      }
    }
    &__title {
      @include print-style;

      color: map-get($map, font-secondary-color);
      font-size: 24px;
      margin-right: map-get($map, spacing);
      @media screen and (max-width: map-get($map, screen-sm)) {
        font-size: map-get($map, font-size-lg);
        margin: 5px 10px 5px 0;
      }
      @media screen and (max-width: map-get($map, screen-xxs)) {
        margin-right: 5px;
      }
    }
    &__label {
      @include print-style;

      background-color: map-get($map, color-gray-light);
      border-radius: 2px;
      color: map-get($map, color-gray);
      font-size: map-get($map, font-size-sm);
      font-weight: map-get($map, font-weight-strong);
      letter-spacing: 0.4px;
      padding: map-get($map, spacing-half) / 2;
      text-transform: uppercase;
      @media screen and (max-width: map-get($map, screen-sm)) {
        font-size: map-get($map, font-size-xs);
        margin: 5px 5px 5px 0;
      }
      &--error {
        @include status-label(map-get($map, color-error));
      }
      &--success {
        @include status-label(map-get($map, color-success));
      }
      &--processing {
        @include status-label(map-get($map, color-primary));
      }
    }
  }
}
