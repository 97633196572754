@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .buy-finised-info {
    @include print-style {
      border: 1px solid map-get($map, modal-shadow-color);
      box-shadow: none;
    }

    background-color: map-get($map, page-tertiary-bg-color);
    border-radius: 6px;
    box-shadow: 0 8px 44px 0 map-get($map, modal-shadow-color);
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    width: 0;
    &:not(:last-child) {
      margin-right: 20px;
      @media screen and (max-width: map-get($map, screen-lg)) {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: map-get($map, screen-lg)) {
      display: flex;
      flex: auto;
      flex-direction: column;
      margin: 20px 10px;
      max-width: 50%;
      width: calc(50% - 20px);
    }
    @media screen and (max-width: map-get($map, narrow-screen-sm)) {
      margin: 10px 0;
      margin-bottom: 20px;
      max-width: 100%;
      width: 100%;
    }
    &__oval-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    &__oval {
      align-items: center;
      background-color: map-get($map, color-gray-light);
      border-radius: 50%;
      color: map-get($map, color-gray-dark);
      display: inline-flex;
      font-weight: bold;
      height: 24px;
      justify-content: center;
      margin-top: map-get($map, spacing);
      width: 24px;
      @media print {
        background-color: map-get($map, page-tertiary-bg-color);
      }
    }
    &__title {
      @include print-style;

      color: map-get($map, color-gray-dark);
      font-size: map-get($map, font-size-sm);
      line-height: 1.5;
      padding: 15px map-get($map, spacing) 0;
      text-align: center;
      &--list {
        margin: 0;
        padding: map-get($map, spacing) map-get($map, spacing)
          map-get($map, spacing) 35px;
        &-item {
          @include print-style;

          color: map-get($map, color-gray-dark);
          font-size: map-get($map, font-size-sm);
          line-height: 1.5;
        }
      }
    }
    &__main {
      @include print-style;

      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 15px map-get($map, spacing) map-get($map, spacing);
      position: relative;
    }
    &__text {
      color: map-get($map, color-primary);
      font-size: map-get($map, font-size-sm);
      font-weight: map-get($map, font-weight-strong);
      left: 50%;
      position: absolute;
      top: 145px;
      transform: translate(-50%, -50%);
    }
    @media print {
      a {
        color: inherit !important; //sass-lint:disable-line no-important
      }
    }
  }
}
