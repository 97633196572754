@import "./../../../resources/styles/common/mixins.scss";
@import "./../../../resources/styles/themes/themify";
@include themify {
  .important-messages {
    background-color: map-get($map, color-primary-light);
    color: map-get($map, color-white);
    position: sticky;
    text-align: center;
    top: 0;
    z-index: 1001;
    @media screen and (max-width: map-get($map, screen-sm)) {
      padding: map-get($map, spacing-half);
    }
    @media screen and (max-width: map-get($map, screen-sm)) {
      font-size: map-get($map, font-size-sm);
    }
    &__close-icon-btn {
      position: absolute;
      right: map-get($map, spacing-half);
      top: map-get($map, spacing);
      &:hover {
        @include icon-color(map-get($map, color-white));
      }
    }
    &__message {
      @include icon-color(map-get($map, color-white));

      align-items: center;
      display: flex;
      justify-content: center;
      padding: map-get($map, spacing) map-get($map, spacing-double);
      position: relative;
      button {
        border-radius: 0;
        color: map-get($map, color-white);
        display: inline-block;
        font-size: inherit;
        font-weight: map-get($map, font-weight-extra-strong);
        min-height: auto;
        padding: 0;
        text-transform: lowercase;
        width: auto;
        &:hover {
          color: map-get($map, color-white);
        }
        @media screen and (max-width: map-get($map, screen-sm)) {
          padding: 0;
        }
      }
      a {
        color: map-get($map, color-white);
        font-weight: map-get($map, font-weight-extra-strong);
        text-decoration: underline;
        text-transform: lowercase;
        &:hover {
          color: map-get($map, color-white);
        }
      }
    }
  }
}
