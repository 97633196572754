@import "./breakpoint.theme.variables";
@import "./font.theme.variables";
@import "./layout.theme.variables";
@import "./icon.theme.variables";
$color_variables_map: (
  "color-white": #fff,
  "color-black": #000,
  // If you want to reverse to orange uncomment this comment same for pink
    // "color-primary": #ff9200,
    // "color-primary-dark": #cb7502,
    // "color-primary-dark-1": #fc810b,
    "color-primary": #e6007e,
  "color-primary-dark": #ff279d,
  "color-primary-dark-1": #a6005b,
  "color-primary-light": #e85598,
  "color-primary-lighter": #ff80c6,
  "color-success": #54cb86,
  "color-success-dark": #04b74f,
  "color-error": #f25268,
  "color-error-light": rgba(227, 7, 44, 0.1),
  "facebook-brand-color": #3b5998,
  "twitter-brand-color": #38a1f3,
  "instagram-brand-color": #d93175,
  "linkedin-brand-color": #0077b5,
  "action-icon-color": #bcc5da,
  "modal-shadow-color": rgba(0, 0, 0, 0.1),
  "tooltip-shadow-color": rgba(0, 0, 0, 0.39),
  "dropdown-shadow-color": rgba(12, 40, 97, 0.06),
  "social-network-icons-color": rgba(255, 255, 255, 0.4)
);
$common_variables_map: ();
// sass-lint:disable indentation
@each $map
  in (
    $breakpoint_variables_map,
    $color_variables_map,
    $layout_variables_map,
    $font_variables_map,
    $icon_variables_map
  )
{
  $common_variables_map: map-merge($common_variables_map, $map);
}
