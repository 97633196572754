@import "../../../resources/styles/themes/themify.scss";
$themes: dark, light;
.introjs {
  &-button {
    background-color: getThemeVariable(color-primary);
    background-image: none;
    border-style: none;
    color: getThemeVariable(font-primary-color);
    font-size: getThemeVariable(font-size);
    font-weight: getThemeVariable(font-weight-strong);
    padding: getThemeVariable(spacing-half);
    text-shadow: none;
    &:hover {
      background-color: getThemeVariable(color-primary-dark);
      box-shadow: none;
    }
    &:focus,
    &:active {
      background-color: getThemeVariable(color-primary-dark);
      background-image: none;
    }
  }
  &-progressbar {
    background-color: getThemeVariable(color-primary);
  }
  &-tooltip {
    padding: getThemeVariable(spacing);
  }
  &-hint-dot {
    border-color: getThemeVariable(color-primary);
  }
  &-hint-pulse {
    background-color: getThemeVariable(color-primary);
    border-color: getThemeVariable(color-primary-dark);
  }
}

@each $theme in $themes {
  .#{$theme}-theme {
    .introjs-bullets ul li a {
      background: getThemeVariable(font-primary-color, $theme);
      &:hover,
      &.active {
        background: getThemeVariable(color-primary, $theme);
      }
    }
    .introjs-tooltip {
      background-color: getThemeVariable(page-primary-bg-color, $theme);
      box-shadow: 0 1px 10px getThemeVariable(page-primary-hover-color, $theme);
    }
    .introjs-helperLayer {
      background-color: getThemeVariable(page-primary-bg-color, $theme);
      box-shadow: 0 2px 15px getThemeVariable(page-primary-hover-color, $theme);
    }
    .introjs-tooltiptext {
      color: getThemeVariable(font-primary-color, $theme);
    }
    .introjs-overlay {
      background: getThemeVariable(guide-bg-color, $theme);
      z-index: 1390;
    }
    .introjs-arrow {
      &.top,
      &.top-right,
      &.top-middle {
        border-bottom-color: getThemeVariable(page-primary-bg-color, $theme);
      }
      &.right,
      &.right-bottom {
        border-bottom-color: getThemeVariable(page-primary-bg-color, $theme);
      }
      &.bottom,
      &.bottom-right {
        border-top-color: getThemeVariable(page-primary-bg-color, $theme);
      }
      &.left,
      &.left-bottom {
        border-right-color: getThemeVariable(page-primary-bg-color, $theme);
      }
    }
  }
}
