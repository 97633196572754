@import "../../../resources/styles/common/mixins";
@import "../../../resources/styles/themes/themify";
@include themify {
  .layout {
    background-color: map-get($map, page-secondary-bg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    &__main {
      @include max-content-width(map-get($map, spacing-double));
      @include mobile {
        padding: map-get($map, spacing);
      }

      background-color: map-get($map, page-secondary-bg-color);
    }
  }
}
