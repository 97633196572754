$breakpoint_variables_map: (
  "input-xs": 250px,
  "input-sm": 400px,
  "screen-xxs": 320px,
  "screen-xs": 425px,
  "screen-sm": 768px,
  "screen-md": 992px,
  "screen-lg": 1200px,
  "narrow-screen-md": 940px,
  "narrow-screen-sm": 610px,
  "mui-screen-sm": 599px,
  "mui-screen-md": 959px,
  "max-content": 1300px
);
