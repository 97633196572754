@import "../../../../resources/styles/themes/themify";
@include themify {
  .header {
    align-items: stretch;
    background: map-get($map, color-primary);
    box-shadow: none;
    display: flex;
    flex-direction: row;
  }
}
