@import "./../../../resources/styles/themes/themify";
@import "../../../resources/styles/common/mixins";
@include themify {
  .input {
    @include native-input-default;
    @include native-input-label-default;

    margin-bottom: 3px;
    width: 100%;
    &__error {
      color: map-get($map, color-error);
    }
  }
}
