@import "../../../resources/styles/common/fonticons/icon.variables";
@import "../../../resources/styles/themes/themify";

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
@mixin mobile {
  @media only screen and (max-width: map-get($map, screen-sm)) {
    @content;
  }
}
@mixin not-mobile {
  @media only screen and (min-width: map-get($map, screen-sm)) {
    @content;
  }
}
@mixin max-content-width($vertical-padding: 0) {
  flex: 1;
  padding: $vertical-padding map-get($map, spacing);
  @media screen and (min-width: map-get($map, screen-lg)) {
    margin: 0 auto;
    max-width: map-get($map, max-content);
    padding: $vertical-padding map-get($map, spacing);
    width: 100%;
  }
}
@mixin min-screen-md {
  @media only screen and (min-width: map-get($map, screen-md)) {
    @content;
  }
}
@mixin max-screen-md {
  @media only screen and (max-width: map-get($map, screen-md)) {
    @content;
  }
}
@mixin native-input-default {
  input {
    color: map-get($map, font-primary-color);
    font-family: map-get($map, font-family-primary);
    font-size: map-get($map, font-size);
    line-height: 0.56;
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px map-get($map, page-tertiary-bg-color) inset;
      -webkit-text-fill-color: map-get($map, font-primary-color) !important;
    }
    &::placeholder {
      line-height: normal;
    }
  }
}
@mixin native-input-label-default {
  label {
    color: map-get($map, font-tertiary-color);
    font-family: map-get($map, font-family-primary);
    line-height: 0.92;
    @media screen and (max-width: map-get($map, screen-xs)) {
      font-size: map-get($map, font-size-sm);
      &[data-shrink="true"] {
        font-size: map-get($map, font-size);
      }
    }
  }
}
@mixin bundles-bundle($border-color) {
  border: 1px solid $border-color;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: map-get($map, spacing-half) map-get($map, spacing-half) / 2 0 0;
  padding: map-get($map, spacing-half);
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: map-get($map, narrow-screen-sm)) {
    &:not(:last-child) {
      flex: 1;
      flex-basis: 100%;
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
@mixin bundle-from {
  color: map-get($map, font-secondary-color);
  font-size: map-get($map, font-size-sm);
  font-weight: map-get($map, font-weight-extra-strong);
}
@mixin bundle-to {
  color: map-get($map, font-tertiary-color);
  font-size: map-get($map, font-size-xs);
  margin-top: 1px;
}
@mixin page-title {
  @include mobile {
    font-size: 24px;
  }

  color: map-get($map, font-primary-color);
  font-size: 36px;
  font-weight: map-get($map, font-weight-extra-strong);
  line-height: 1.33;
  margin: 0;
  text-align: center;
}
@mixin form-content-wrapper {
  @include mobile {
    margin-top: 0;
    padding: 0 map-get($map, spacing);
  }

  margin: map-get($map, spacing-double) auto 0;
  min-height: 200px;
  text-align: center;
  @media screen and (min-width: map-get($map, screen-xs)) {
    max-width: map-get($map, narrow-screen-sm);
  }
  @media screen and (min-width: map-get($map, screen-lg)) {
    max-width: map-get($map, narrow-screen-md);
  }
}
@mixin narrow-content {
  @include not-mobile {
    @include clearfix;
  }
  @media screen and (min-width: map-get($map, screen-lg)) {
    margin: 0 auto;
    max-width: 90%;
  }
}
@mixin form-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
@mixin input-min-layout($min-width: map-get($map, input-xs)) {
  margin: 0 auto;
  position: relative;
  width: $min-width;
  @media screen and (max-width: map-get($map, screen-sm)) {
    width: 100%;
  }
}
@mixin tooltip-info-icon {
  color: map-get($map, page-primary-link-color);
  padding: 0;
  &:hover {
    i {
      border: 1px solid map-get($map, font-secondary-color);
    }
  }
  i {
    align-items: center;
    border: 1px solid map-get($map, page-primary-link-color);
    border-radius: 50%;
    display: flex;
    height: 18px;
    justify-content: center;
    width: 18px;
    &::before {
      font-size: 8px;
    }
  }
  &--is-open {
    i {
      border: 1px solid map-get($map, font-secondary-color);
      &::before {
        color: map-get($map, font-secondary-color);
      }
    }
  }
}
@mixin table {
  background-color: map-get($map, page-tertiary-bg-color);
  font-size: map-get($map, font-size);
  overflow-x: auto;
  @media only screen and (max-width: map-get($map, screen-md)) {
    display: flex;
    flex-direction: column;
    table,
    tbody {
      display: block;
    }
    thead > tr {
      display: none;
    }
  }
}
@mixin table-thead {
  color: map-get($map, color-gray);
  font-size: map-get($map, font-size-sm);
  line-height: 28px;
  padding: 4px 0 4px 4px;
  vertical-align: middle;
  @media screen and (max-width: map-get($map, screen-lg)) {
    font-size: 13px;
  }
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
}
@mixin table-row {
  white-space: nowrap;
  @media screen and (max-width: map-get($map, screen-md)) {
    border-bottom: 20px solid map-get($map, page-secondary-bg-color);
    display: block;
    height: auto;
    position: relative;
  }
  &:last-child {
    border-bottom: 2px solid map-get($map, page-tertiary-bg-color);
  }
  &:hover {
    background-color: map-get($map, page-primary-hover-color);
    cursor: pointer;
  }
}
@mixin table-cell {
  border-bottom: 1px solid map-get($map, table-border-color);
  color: map-get($map, font-primary-color);
  line-height: 28px;
  padding: 15px 4px;
  &:first-child {
    padding-left: map-get($map, spacing);
  }
  @media screen and (max-width: map-get($map, screen-lg)) {
    font-size: 12px;
  }
  @media screen and (max-width: map-get($map, screen-md)) {
    border: 1px solid map-get($map, table-border-color);
    border-bottom: 0;
    display: block;
    font-size: 14px;
    line-height: 38px;
    padding: 10px;
    position: relative;
    text-align: right;
    &:last-of-type {
      border: 1px solid map-get($map, table-border-color);
      padding-right: 10px;
    }
    &::before {
      content: attr(data-title);
      font-weight: map-get($map, font-weight-extra-strong);
      left: 10px;
      position: absolute;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: map-get($map, screen-xs)) {
    font-size: 13px;
  }
  &--strong {
    font-weight: map-get($map, font-weight-extra-strong);
  }
  &--empty {
    @media screen and (max-width: map-get($map, screen-md)) {
      display: none;
    }
  }
}
@mixin print-style {
  @media print {
    background-color: map-get($map, color-white);
    color: map-get($map, color-black);
    @content;
  }
}
@mixin link {
  color: map-get($map, page-primary-link-color);
  text-decoration: none;
  &:hover {
    color: map-get($map, color-primary-dark);
    text-decoration: underline;
  }
  @media print {
    color: map-get($map, color-black);
  }
}

@mixin status-label($bg-color) {
  background-color: $bg-color;
  color: map-get($map, color-white);
}

@mixin transaction-direction($color, $rotate) {
  align-items: center;
  display: inline-flex;
  i {
    margin-left: 0;
    &::before {
      color: $color;
      display: inline-block;
      transform: rotate($rotate);
    }
  }
}
@mixin title {
  color: map-get($map, font-secondary-color);
  font-size: 24px;
  padding: 25px 0 map-get($map, spacing-half);
  @media screen and (max-width: map-get($map, screen-sm)) {
    align-items: center;
    display: flex;
    font-size: map-get($map, font-size-lg);
    justify-content: space-between;
  }
  @media screen and (max-width: map-get($map, screen-sm)) {
    padding-top: map-get($map, spacing-half);
  }
  @media print {
    padding-top: map-get($map, spacing-half);
  }
}
@mixin low-res-font($screen) {
  @media screen and (max-width: $screen) {
    font-size: map-get($map, font-size-sm);
  }
}

@mixin buy-sell-modal-content {
  color: map-get($map, color-gray-dark);
  &__main {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: map-get($map, screen-sm)) {
      padding: 0 map-get($map, spacing);
    }
  }
  &__actions {
    border-top: 1px solid map-get($map, color-gray-light);
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: map-get($map, spacing);
    @media screen and (max-width: map-get($map, screen-xs)) {
      button {
        flex: 1;
        font-size: map-get($map, font-size-xs);
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__title {
    color: map-get($map, font-secondary-color);
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    margin-top: map-get($map, spacing-double);
    text-align: center;
    @media screen and (max-width: map-get($map, screen-sm)) {
      font-size: map-get($map, font-size-lg);
      margin-top: map-get($map, spacing);
    }
  }
  &__subtitle {
    color: map-get($map, color-gray-dark);
    font-size: map-get($map, font-size-sm);
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (min-width: map-get($map, screen-sm)) {
      margin: 0 auto 30px;
      max-width: 80%;
    }
  }
  &__amounts {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: map-get($map, screen-xs)) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &__amounts-item {
    &:first-child {
      @media screen and (max-width: map-get($map, screen-xs)) {
        margin-bottom: map-get($map, spacing-half);
      }
    }
  }
  &__amount-title {
    font-size: map-get($map, font-size-sm);
    text-align: center;
  }
  &__amount-value {
    color: map-get($map, font-secondary-color);
    font-size: 24px;
    margin-top: 7px;
    text-align: center;
    @media screen and (max-width: map-get($map, screen-sm)) {
      font-size: map-get($map, font-size-lg);
    }
  }
}
@mixin price-change($color) {
  font-size: map-get($map, font-size-xs) * 0.85;
  @if $color {
    color: $color;
  }
}
@mixin arrow-down {
  content: $icon-arrow-down;
  font-family: map-get($map, font-family-btcx-protected-web);
}
@mixin mobile-links {
  background-color: map-get($map, color-primary);
  display: flex;
  flex-direction: column;
  font-weight: map-get($map, font-weight-strong);
  left: 0;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  top: 61px;
  transition: 0.3s;
  width: 100%;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    text-align: left;
    background-color: map-get($map, color-primary);
  }
  a {
    border-bottom: 0;
  }
}
@mixin mobile-menu-logo($icon-color) {
  padding: 0;
  &:hover {
    background-color: map-get($map, color-primary);
    cursor: pointer;
    i::before {
      color: $icon-color;
    }
  }
  i {
    margin: map-get($map, spacing-half) 0 map-get($map, spacing-half)
      map-get($map, spacing-half);
    &::before {
      color: $icon-color;
    }
  }
}
@mixin toggle-indicator {
  background-color: map-get($map, page-primary-bg-color);
  border-radius: 50%;
  display: inline-flex;
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
  z-index: 2;
}
@mixin recharts-color {
  .recharts {
    &-cartesian-axis-line {
      stroke: map-get($map, font-primary-color);
      stroke-width: 0.3;
    }
    &-text,
    &-cartesian-axis-tick-value {
      fill: map-get($map, font-primary-color);
      font-size: map-get($map, font-size-sm);
    }
  }
}

@mixin icon-color($color) {
  i {
    &::before {
      color: $color;
    }
  }
}

@mixin btcx-logo-color {
  // Uncomment if reverse back to old logo
  // -webkit-filter: map-get($map, btcx-logo-filter);
  // -moz-filter: map-get($map, btcx-logo-filter);
  // -ms-filter: map-get($map, btcx-logo-filter);
  // -o-filter: map-get($map, btcx-logo-filter);
  // filter: map-get($map, btcx-logo-filter);
}
