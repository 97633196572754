$icon-alert-sign: "\e911";
$icon-arrow-down: "\e910";
$icon-arrow-right: "\e90f";
$icon-bell: "\e902";
$icon-buy: "\e90c";
$icon-check: "\e90b";
$icon-close: "\e90a";
$icon-copy: "\e909";
$icon-facebook: "\e907";
$icon-hamburger: "\e908";
$icon-info: "\e906";
$icon-instagram: "\e916";
$icon-linkedin: "\e915";
$icon-moon: "\e913";
$icon-more-vert: "\e904";
$icon-print: "\e903";
$icon-sell: "\e901";
$icon-send: "\e917";
$icon-sun: "\e914";
$icon-twitter: "\e900";
$icon-minus: "\e905";
