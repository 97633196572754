// sass-lint:disable indentation
$font_variables_map: (
  "font-family-primary": #{"Ubuntu",
  sans-serif},
  "font-family-mono": #{"Roboto Mono",
  monospace},
  "font-family-btcx-protected-web": "btcx-protected-web",
  "font-weight-extra-strong": 700,
  "font-weight-strong": 500,
  "font-weight-default": 400,
  "font-size-lg": 18px,
  "font-size": 16px,
  "font-size-sm": 14px,
  "font-size-xs": 12px,
  "font-size-xxs": 10px
);
