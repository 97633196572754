@import "./../../../resources/styles/themes/themify";
@include themify {
  .notifications {
    background-color: map-get($map, page-tertiary-bg-color);
    border-radius: 8px;
    box-shadow: 0 34px 34px 0 map-get($map, dropdown-shadow-color);
    margin-top: 15px;
    max-height: 500px;
    overflow-y: auto;
    padding: 0;
    @media screen and (max-width: map-get($map, screen-xs)) {
      border-radius: 0;
      margin-top: 5px;
    }
    &__menu {
      border-radius: 8px;
      padding: 0;
      z-index: 999;
      @media screen and (max-width: map-get($map, screen-md)) and (min-width: map-get($map, screen-sm) + 1) {
        margin-right: 13px;
        min-width: auto;
      }
      & ~ & {
        left: -10px;
        @media screen and (min-width: map-get($map, screen-sm) + 1) {
          margin-top: 4px;
        }
        @media screen and (max-width: map-get($map, screen-xs)) {
          border-radius: 0;
          max-width: 100%;
          transform: translate(0, 61px) !important;
          width: 100%;
        }
      }
    }
    &__notification {
      display: flex;
      font-family: map-get($map, font-family-primary);
      height: 100%;
      justify-content: space-between;
      padding: 20px;
      &:not(:last-child) {
        border-bottom: 1px solid map-get($map, table-border-color);
      }
      &:hover {
        background-color: inherit;
        cursor: auto;
      }
    }
    &__notification-main {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;
      button {
        font-weight: map-get($map, font-weight-default);
        margin-top: map-get($map, spacing-half);
        min-height: auto;
        width: auto;
      }
    }
    &__notification-header {
      display: flex;
      justify-content: space-between;
    }
    &__notification-title {
      color: map-get($map, font-secondary-color);
      display: block;
      font-size: map-get($map, font-size);
      white-space: normal;
    }
    &__notification-content {
      color: map-get($map, font-secondary-color);
      display: block;
      font-size: map-get($map, font-size-sm);
      margin-top: 10px;
      white-space: normal;
      word-break: break-all;
    }
    &__notification-date {
      color: map-get($map, font-tertiary-color);
      font-size: map-get($map, font-size-sm);
      margin-left: 20px;
    }
    &__empty {
      color: map-get($map, font-tertiary-color);
      font-size: map-get($map, font-size-sm);
      padding: map-get($map, spacing-double);
      text-align: center;
    }
    &__icon {
      align-items: center;
      color: map-get($map, color-white);
      display: inline-flex;
      &--opened {
        color: map-get($map, color-primary-lighter);
        display: inline-flex;
      }
    }
    button[data-test="view-all-btn"] {
      width: 100%;
    }
    &__icon-wrapper {
      position: relative;
    }
  }
}

@include themify {
  .notifications {
    &--non-processed {
      font-weight: map-get($map, font-weight-extra-strong);
      width: 100%;
    }
    &--processed {
      width: 100%;
    }
    &--has-non-processed {
      background-color: map-get($map, color-primary);
      border-radius: 4px;
      height: 8px;
      left: 60%;
      position: absolute;
      top: -5px;
      width: 8px;
    }
  }
}
