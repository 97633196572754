@import "./../../../resources/styles/common/mixins.scss";
@import "./../../../resources/styles/themes/themify";
@include themify {
  .copy {
    align-items: center;
    color: map-get($map, page-primary-link-color);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media print {
      display: none;
    }
    &__feedback {
      @include icon-color(map-get($map, color-success));

      align-items: center;
      background-color: map-get($map, page-tertiary-bg-color);
      border-radius: 6px;
      box-shadow: 0 2px 7px 0 map-get($map, tooltip-shadow-color);
      color: map-get($map, color-gray-dark);
      display: flex;
      font-size: map-get($map, font-size-xs);
      justify-content: center;
      min-width: 200px;
      padding: map-get($map, spacing-half);
      position: absolute;
      top: -50px;
      z-index: 2;
    }
    &__content {
      align-items: center;
      display: flex;
      font-size: map-get($map, font-size-sm);
      font-weight: map-get($map, font-weight-strong);
      min-width: auto;
      &:hover {
        @include icon-color(map-get($map, color-primary-dark));

        background-color: transparent;
        color: map-get($map, color-primary-dark);
        cursor: pointer;
      }
      &--label {
        min-height: auto;
        padding: map-get($map, spacing-half);
        @media screen and (max-width: map-get($map, screen-xs)) {
          font-size: map-get($map, font-size-xs);
        }
      }
    }
  }
}
