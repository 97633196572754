@import "../../../../../resources/styles/themes/themify";
@include themify {
  .navigation {
    &__logo {
      padding-right: map-get($map, spacing-half);
      @media screen and (min-width: map-get($map, screen-md)) {
        margin-right: map-get($map, spacing);
      }
    }
  }
}
