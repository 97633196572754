@import "./common/mixins";
@import "../styles/themes/themify";
@include themify {
  .lowercase {
    text-transform: lowercase;
  }
  .flex-wrap-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__desktop-half {
      @include mobile {
        width: 100%;
      }

      width: 48%;
      &--end {
        @include mobile {
          margin-left: 0;
        }

        margin-left: 52%;
        width: 100%;
      }
    }
  }
  .btn {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    display: block;
    font-family: map-get($map, font-family-primary);
    font-size: map-get($map, font-size-sm);
    font-weight: map-get($map, font-weight-default);
    height: 100%;
    line-height: 1;
    padding: map-get($map, spacing) map-get($map, spacing-double);
    text-align: inherit;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
    &:hover {
      background-color: transparent;
    }
    @media screen and (max-width: map-get($map, screen-sm)) {
      font-weight: map-get($map, font-weight-strong);
      line-height: inherit;
      padding: 15px map-get($map, spacing);
    }
  }
  .color-inherit {
    color: inherit;
  }
  .currency-font {
    font-family: map-get($map, font-family-mono);
  }
}
