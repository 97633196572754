@import "./../../../resources/styles/themes/themify";
@include themify {
  .print {
    display: inline-block;
    @media print {
      display: none;
    }
    &__btn {
      color: map-get($map, page-primary-link-color);
      min-height: auto;
      padding: 0 8px;
      white-space: nowrap;
      @media screen and (max-width: map-get($map, screen-xs)) {
        padding: 0;
      }
      &:hover {
        background-color: transparent;
        color: map-get($map, font-secondary-color);
      }
    }
  }
}
