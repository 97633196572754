@import "./../../../resources/styles/themes/themify";
@include themify {
  .link {
    color: map-get($map, page-primary-link-color);
    font-size: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
