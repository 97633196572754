@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .order-details {
    &__main {
      padding: map-get($map, spacing-half) map-get($map, spacing)
        map-get($map, spacing);
      @media print {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
    &__error {
      color: map-get($map, font-secondary-color);
      &-content {
        text-align: center;
      }
      &-heading {
        @include title;

        border-bottom: 1px solid map-get($map, color-gray-light);
        line-height: 20px;
        @media screen and (min-width: map-get($map, screen-xxs)) {
          padding: 20px;
        }
      }
      &-link {
        @include link;
      }
    }
    &__title {
      @include title;
    }
    &__additional-info {
      page-break-before: always;
    }
    &__item {
      @include low-res-font(map-get($map, screen-xs));

      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      font-size: map-get($map, font-size);
      font-weight: map-get($map, font-weight-strong);
      justify-content: space-between;
      margin-bottom: map-get($map, spacing-half);
      @media print {
        margin-bottom: map-get($map, spacing-half) / 2;
      }
      @media screen and (max-width: map-get($map, screen-xs)) {
        align-items: flex-start;
        flex-direction: column;
      }
      &-value {
        @include print-style;

        color: map-get($map, font-secondary-color);
        margin-top: 5px;
        @media screen and (min-width: (map-get($map, screen-sm) + 1)) {
          font-size: map-get($map, font-size-lg);
        }
        @media screen and (max-width: map-get($map, screen-xs)) {
          width: 80%;
          word-break: break-all;
        }
      }
      &-label {
        @include print-style {
          opacity: 1;
        }

        color: map-get($map, color-gray);
        margin-top: 5px;
        opacity: 0.6;
        padding-right: map-get($map, spacing);
      }
    }
    &__copy-blockexplorer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      &--center {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    &__info {
      @include print-style;
      @include low-res-font(map-get($map, screen-sm));

      color: map-get($map, color-gray-dark);
      line-height: 1.5;
      margin: map-get($map, spacing-half) 0 map-get($map, spacing);
    }
    &__sell-payment {
      padding: map-get($map, spacing-half) map-get($map, spacing) 0;
    }
    &__sel-payment-title {
      @include title;
    }
    &__sel-payment-desc {
      @include print-style;
      @include low-res-font(map-get($map, screen-sm));

      color: map-get($map, color-gray-dark);
      line-height: 1.5;
      margin-bottom: map-get($map, spacing-double);
      @media screen and (max-width: map-get($map, screen-xs)) {
        margin-bottom: map-get($map, spacing);
      }
    }
    &__sel-payment-crypto-label {
      @include print-style;
      @include low-res-font(map-get($map, screen-sm));

      color: map-get($map, color-gray-dark);
      line-height: 1.5;
      padding-bottom: map-get($map, spacing-half);
      text-align: center;
    }
    &__barcode {
      display: flex;
      margin: 0 auto;
    }
    &__transaction-date {
      @media screen and (max-width: map-get($map, screen-md)) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        & > div:first-of-type {
          padding-right: map-get($map, spacing-half);
        }
      }
    }
    &__action {
      float: right;
    }
  }
}
