[class^="icon-"],
[class*=" icon-"] {
  font-family: $font-family-btcx-protected-web;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  margin-left: 10px;
  margin-right: 10px;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
}
