@import "../../../../../resources/styles/themes/themify";
@include themify {
  .btn {
    align-items: center;
    color: map-get($map, page-primary-link-color);
    display: inline-flex;
    font-size: 14px;
    font-weight: map-get($map, font-weight-strong);
    padding-bottom: map-get($map, spacing-half);
    padding-top: map-get($map, spacing-half);
    text-decoration: none;
    @media screen and (max-width: map-get($map, screen-xs)) {
      font-size: map-get($map, font-size-xs);
    }
    @media print {
      display: none;
    }
    &:hover {
      color: map-get($map, color-primary-dark);
    }
    &__icon {
      margin-left: map-get($map, spacing-half);
    }
  }
}
