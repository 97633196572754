@import "../../../resources/styles/common/mixins";
@import "../../../resources/styles/themes/themify";
@include themify {
  .layout {
    background-color: map-get($map, page-secondary-bg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }
    &__header-main {
      align-items: center;
      display: flex;
      button {
        @media screen and (max-width: map-get($map, screen-sm)) {
          min-height: auto;
          min-width: auto;
        }
      }
    }
    &__main {
      @include max-content-width(map-get($map, spacing-double));
      @include mobile {
        padding: map-get($map, spacing);
      }

      background-color: map-get($map, page-secondary-bg-color);
    }
  }
}
