@import "./../../../resources/styles/themes/themify";
@include themify {
  .loader-wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: map-get($map, spacing);
  }
}
