@import "./dark/dark.theme.variables";
@import "./light/light.theme.variables";
$theme_map: (
  "light": $light_variables_map,
  "dark": $dark_variables_map
);

@mixin themify {
  @each $label, $mapa in $theme_map {
    @if $label == "light" {
      $map: $mapa !global;
      @content;
    }

    @if $label == "dark" {
      :global(.dark-theme) {
        $map: $mapa !global;
        @content;
      }
    }

    @if $map {
      $map: null;
    }
  }
}
@function getThemeVariable($var, $target-theme: dark) {
  @return map-get(map-get($theme_map, $target-theme), $var);
}
