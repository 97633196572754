@import "../../../../../../resources/styles/common/mixins";
@import "../../../../../../resources/styles/themes/themify";
@include themify {
  .order-details__footer {
    @include print-style;

    align-items: center;
    background-color: map-get($map, color-gray-light);
    color: map-get($map, color-gray);
    display: flex;
    line-height: 1.38;
    padding: map-get($map, spacing-half) map-get($map, spacing);
    @media print {
      background-color: map-get($map, color-white);
      padding-bottom: 0;
    }
    @media screen and (max-width: map-get($map, narrow-screen-sm)) {
      display: block;
    }
    @media screen and (max-width: map-get($map, screen-xs)) {
      font-size: map-get($map, font-size-sm);
    }
    a {
      @include link;
    }
  }
}
