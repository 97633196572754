@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .desktop {
    @include max-content-width;

    align-items: center;
    background: map-get($map, color-primary);
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    max-width: 100%;
    &__main-menu {
      align-items: center;
      display: flex;
      flex: 1;
    }
  }
}
