@import "../../../resources/styles/common/mixins";
@import "../../../resources/styles/themes/themify";
@include themify {
  .layout {
    background-color: map-get($map, page-primary-bg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
