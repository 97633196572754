@import "./../../../resources/styles/themes/themify";
@include themify {
  .personal-information {
    margin-bottom: 40px;
    margin-top: 40px;
    @media print {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    &__title {
      color: map-get($map, font-secondary-color);
      font-size: 24px;
      margin-bottom: 30px;
      @media print {
        margin-bottom: 10px;
      }
    }
    &__content {
      margin-top: 20px;
      @media print {
        margin-top: 0;
      }
    }
    &__item {
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      padding: map-get($map, spacing-half) / 2;
    }
    &__label {
      color: map-get($map, color-gray-dark);
    }
    &__value {
      color: map-get($map, font-secondary-color);
    }
  }
}
