@import "../../../resources/styles/themes/themify";
@include themify {
  .global-message {
    &__content {
      &--success {
        background-color: map-get($map, color-success);
      }
      &--error {
        background-color: map-get($map, color-error);
      }
      &--info {
        background-color: map-get($map, font-primary-color);
        & button,
        & > div > div {
          color: map-get($map, page-tertiary-bg-color);
        }
      }
    }
    &__close-icon {
      color: map-get($map, color-white);
      position: absolute;
      right: 0;
      top: 0;
      &:hover {
        background-color: transparent;
      }
    }
    &__text {
      color: map-get($map, color-white);
      margin-right: 10px;
    }
  }
}
