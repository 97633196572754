$icons: (
  alert-sign: $icon-alert-sign,
  arrow-down: $icon-arrow-down,
  arrow-right: $icon-arrow-right,
  bell: $icon-bell,
  buy: $icon-buy,
  check: $icon-check,
  close: $icon-close,
  copy: $icon-copy,
  facebook: $icon-facebook,
  hamburger: $icon-hamburger,
  info: $icon-info,
  moon: $icon-moon,
  more-vert: $icon-more-vert,
  print: $icon-print,
  sell: $icon-sell,
  send: $icon-send,
  sun: $icon-sun,
  twitter: $icon-twitter,
  instagram: $icon-instagram,
  linkedin: $icon-linkedin,
  minus: $icon-minus
);

@each $el, $icon in $icons {
  .icon-#{$el} {
    &::before {
      content: $icon;
    }
  }
}
