@import "../../resources/styles/common/mixins";
@import "../../resources/styles/themes/themify";
@include themify {
  .theme-toggler {
    cursor: pointer;
    &__indicator {
      display: none;
    }
    & i {
      &::before {
        color: map-get($map, color-white);
      }
      @media screen and (max-width: map-get($map, screen-md)) and (min-width: map-get($map, screen-sm) + 1) {
        margin: 0 8px;
      }
    }
    @media screen and (max-width: map-get($map, screen-sm)) {
      align-items: center;
      background-color: map-get($map, font-primary-color);
      border-radius: 34px;
      display: flex;
      height: 32px;
      margin: map-get($map, spacing-half) 0 map-get($map, spacing-half)
        map-get($map, spacing);
      position: relative;
      width: 65px;
      & i {
        align-items: center;
        display: inline-flex;
        margin-right: 6px;
        position: absolute;
        right: 0;
        &::before {
          color: map-get($map, page-primary-bg-color);
          font-size: 20px;
        }
      }
      &__indicator {
        @include toggle-indicator;

        transform: none;
        &--dark {
          @include toggle-indicator;

          transform: translateX(32px);
          & ~ i {
            margin-left: 8px;
            position: relative;
          }
        }
      }
    }
    &__min-layout {
      background-color: inherit;
      margin-right: 1em;
      @media screen and (max-width: map-get($map, screen-sm)) {
        margin-right: 1em;
      }
      & i {
        cursor: pointer;
        &::before {
          color: map-get($map, page-primary-link-color);
        }
      }
    }
  }
}
