@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .buy-order-finished {
    padding-bottom: map-get($map, spacing);
    &__title {
      @include print-style;

      color: map-get($map, font-secondary-color);
      font-weight: normal;
      margin: 25px 0 map-get($map, spacing);
      @media screen and (max-width: map-get($map, screen-sm)) {
        font-size: map-get($map, font-size-lg);
        margin-top: map-get($map, spacing-half);
      }
    }
    &__blocks {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: map-get($map, screen-lg)) {
        flex-wrap: wrap;
      }
      @media screen and (max-width: map-get($map, narrow-screen-sm)) {
        flex-direction: column;
      }
    }
    @media print {
      img {
        display: none;
      }
    }
  }
}
