@import "./../../../resources/styles/common/mixins.scss";
@import "./../../../resources/styles/themes/themify";
@include themify {
  .modal {
    box-shadow: 2px 2px 2px map-get($map, color-gray-light);
    &__body {
      min-height: 100px;
      min-width: 100px;
    }
    &__title {
      color: map-get($map, font-secondary-color);
      font-size: 24px;
      font-weight: bold;
      line-height: 1.17;
    }
    &__close-icon-btn {
      @include icon-color(map-get($map, table-border-color));

      position: absolute;
      right: map-get($map, spacing-half);
      top: map-get($map, spacing-half);
      width: 40px;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
