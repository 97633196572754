@import "../../../resources/styles/themes/themify";

@include themify {
  %btn {
    border-radius: 6px;
    font-family: map-get($map, font-family-primary);
    font-size: inherit;
    font-weight: map-get($map, font-weight-extra-strong);
    line-height: 1.33;
    text-transform: capitalize;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @media screen and (max-width: map-get($map, screen-xs)) {
      font-size: map-get($map, font-size-sm);
      width: 100%;
    }
    &[disabled] {
      background-color: map-get($map, table-border-color);
      color: map-get($map, color-white);
    }
  }
  .btn-primary {
    background-color: map-get($map, color-primary);
    color: map-get($map, color-white);
    @extend %btn;
    &:hover {
      background-color: map-get($map, color-primary-dark);
    }
    @media print {
      display: none;
    }
  }
  .btn-primary-outlined {
    border: 2px solid map-get($map, page-primary-link-color);
    color: map-get($map, page-primary-link-color);
    @extend %btn;
    &:hover {
      background-color: map-get($map, color-primary);
      border-color: map-get($map, color-primary);
      color: map-get($map, color-white);
    }
    @media print {
      display: none;
    }
  }
  .btn-primary-text {
    color: map-get($map, page-primary-link-color);
    @extend %btn;
    &:hover {
      background-color: transparent;
      color: map-get($map, color-primary-dark);
    }
  }
  .btn-secondary-text {
    color: map-get($map, font-tertiary-color);
    @extend %btn;
    font-weight: map-get($map, font-weight-default);
  }
  .btn-text-secondary--active {
    color: map-get($map, color-primary);
    @extend %btn;
    font-weight: map-get($map, font-weight-strong);
  }
  .grouped {
    background-color: map-get($map, button-group-bg-color);
    border-radius: 0;
    color: map-get($map, font-tertiary-color);
    display: inline-block;
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    padding: 16px;
    transition: 0.1s;
    &:first-child {
      border-bottom-left-radius: 9px;
      border-top-left-radius: 9px;
    }
    &:last-child {
      border-bottom-right-radius: 9px;
      border-top-right-radius: 9px;
    }
    &:hover {
      background-color: map-get($map, button-group-bg-color);
      cursor: pointer;
    }
    &--active {
      background-color: map-get($map, page-tertiary-bg-color);
      color: map-get($map, page-primary-link-color);
      &:hover {
        background-color: map-get($map, page-tertiary-bg-color);
      }
    }
  }
}
