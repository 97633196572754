@import "../../../../resources/styles/common/mixins";
@import "../../../../resources/styles/themes/themify";
@include themify {
  .footer {
    background-color: map-get($map, footer-bg-color);
    display: flex;
    font-size: map-get($map, font-size-sm);
    justify-content: center;
    padding: map-get($map, spacing-double) 0;
    text-align: center;
    @media screen and (max-width: map-get($map, screen-md)) {
      flex-direction: column;
    }
    &--is-hidden {
      .footer__base-columns,
      .footer__social-column {
        @media screen and (max-width: map-get($map, screen-md)) {
          display: none;
        }
      }
    }
    &__content-wrapper {
      @include max-content-width;

      display: flex;
      @media screen and (max-width: map-get($map, screen-md)) {
        display: block;
      }
      @media screen and (min-width: map-get($map, screen-lg)) {
        max-width: 1170px;
      }
    }
    &__base-columns {
      display: flex;
      justify-content: space-around;
      width: 100%;
      @media screen and (max-width: map-get($map, screen-md)) {
        display: block;
      }
      a {
        color: map-get($map, color-white);
        display: inline-block;
        font-weight: map-get($map, font-weight-extra-strong);
        margin-bottom: map-get($map, spacing);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__column {
      display: flex;
      flex-direction: column;
      &--wide {
        min-width: 260px;
        @media screen and (max-width: 1199px) {
          min-width: 230px;
        }
      }
    }
    &__heading {
      color: map-get($map, action-icon-color);
      font-size: map-get($map, font-size-sm);
      font-weight: map-get($map, font-weight-default);
      line-height: 28px;
      margin-bottom: map-get($map, spacing);
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    &__payment {
      &-heading {
        color: map-get($map, action-icon-color);
        font-size: map-get($map, font-size-sm);
        font-weight: map-get($map, font-weight-default);
        margin: map-get($map, spacing) 0 20px;
      }
      &-logo {
        border-radius: 2px;
        height: 18px;
        margin: 0 12px 12px 0;
        width: auto;
        &:last-of-type {
          margin-right: 0;
        }
        &-wrapper {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 auto;
          max-width: 230px;
        }
      }
    }
    &__social {
      display: flex;
      flex-direction: column;
      &-heading {
        color: map-get($map, action-icon-color);
        font-size: map-get($map, font-size-sm);
        font-weight: map-get($map, font-weight-default);
        margin: map-get($map, spacing) 0 15px;
      }
      &-networks {
        white-space: nowrap;
        a {
          align-items: center;
          background-color: map-get($map, color-white);
          border-radius: 50%;
          display: inline-flex;
          height: 32px;
          justify-content: center;
          text-decoration: none;
          width: 32px;
          & + a {
            margin-left: 5%;
            @media screen and (max-width: map-get($map, screen-md)) {
              margin-left: map-get($map, spacing);
            }
          }
          i {
            margin: 0;
          }
          &:not(:hover) {
            background-color: map-get($map, social-network-icons-color);
            i::before {
              color: map-get($map, font-primary-color);
            }
          }
        }
        &--facebook {
          @include icon-color(map-get($map, facebook-brand-color));
        }
        &--twitter {
          @include icon-color(map-get($map, twitter-brand-color));
        }
        &--instagram {
          @include icon-color(map-get($map, instagram-brand-color));
        }
        &--linkedin {
          @include icon-color(map-get($map, linkedin-brand-color));
        }
      }
    }
    &__separator {
      border: 0;
      border-top: 1px solid;
      color: map-get($map, action-icon-color);
      font-weight: map-get($map, font-weight-default);
      margin: 8px auto 20px;
      opacity: 0.22;
      width: 35%;
    }
    &__copyright {
      color: map-get($map, action-icon-color);
      display: flex;
      justify-content: center;
      margin: 0 0 30px;
      opacity: 0.4;
      @media screen and (max-width: map-get($map, screen-md)) {
        margin: 0;
      }
    }
    &__collapse-action {
      display: none;
      font-weight: map-get($map, font-weight-extra-strong);
      margin-left: 20px;
      transform: rotate(180deg);
      @media screen and (max-width: map-get($map, screen-md)) {
        display: block;
      }
      &:hover {
        cursor: pointer;
      }
      &::after {
        @include arrow-down;

        color: map-get($map, color-white);
      }
      &--is-collapsed {
        transform: rotate(0);
      }
    }
  }
}
