@import "./../../../resources/styles/common/mixins";
@import "./../../../resources/styles/themes/themify";
@include themify {
  .account-menu {
    background-color: map-get($map, color-primary);
    color: map-get($map, color-white);
    padding: 0;
    text-align: left;
    z-index: 999;
    @media screen and (min-width: map-get($map, screen-sm) + 1) {
      font-weight: map-get($map, font-weight-default);
    }
    @media screen and (max-width: map-get($map, screen-sm)) {
      background-color: map-get($map, color-primary);
    }
    &--mobile {
      background-color: map-get($map, color-primary);
      background-color: map-get($map, color-primary);
      box-shadow: none !important;
      justify-content: flex-start;
      position: relative !important; //sass-lint:disable-line no-important
      width: 100%;
      &[aria-owns="menu-list-grow"] {
        color: map-get($map, color-white);
        transition: color 0.3s ease 0s;
        i {
          transform: rotate(-180deg);
          &::before {
            color: map-get($map, color-primary-lighter);
            transition: color 0.3s ease 0s;
          }
        }
      }
    }
    &__item {
      font-size: map-get($map, font-size-sm);
      height: 100%;
      padding: 0;
      &:hover {
        background-color: map-get($map, color-primary-light);
      }
      @media screen and (max-width: map-get($map, screen-sm)) {
        & > a,
        & > button {
          font-weight: map-get($map, font-weight-strong);
        }
        &:hover {
          background-color: inherit;
        }
      }
      a,
      button {
        color: map-get($map, color-white);
        line-height: 14px;
        padding: map-get($map, spacing);
        text-decoration: none;
        text-transform: none;
        width: 100%;
        @media screen and (max-width: map-get($map, screen-sm)) {
          background-color: map-get($map, color-primary);
          color: map-get($map, color-white);
          font-size: map-get($map, font-size);
          line-height: 30px;
          padding: map-get($map, spacing-half) 0 map-get($map, spacing-half)
            map-get($map, spacing);
        }
      }
      &--active {
        a,
        button {
          color: map-get($map, color-primary-lighter);
          @media screen and (max-width: map-get($map, screen-sm)) {
            color: map-get($map, color-primary-lighter) !important;
          }
        }
      }
    }
    &[aria-haspopup="true"] {
      & div {
        align-items: center;
        display: flex;
      }
      @media screen and (max-width: map-get($map, screen-md)) and (min-width: (map-get($map, screen-sm) + 1)) {
        padding-left: 0;
      }
    }
    &[aria-owns="menu-list-grow"] {
      i {
        transform: rotate(-180deg);
      }
    }
    & i {
      &::before {
        color: map-get($map, color-white);
      }
    }
    &[role="tooltip"] {
      border-radius: 8px;
      box-shadow: 0 34px 34px 0 map-get($map, dropdown-shadow-color);
      margin-top: 3px;
      min-width: 200px;
      overflow: hidden;
      @media screen and (max-width: map-get($map, screen-sm)) {
        border-radius: 0;
        margin-top: 0;
        padding: 0;
        transform: translate(0) !important;
      }
    }
    &__email {
      color: map-get($map, color-white);
      font-size: 12px;
      margin-top: 2px;
      max-width: 125px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    &__wrapper {
      background-color: map-get($map, color-primary);
      margin-top: 15px;
      padding-left: 20px;
      > button {
        background-color: map-get($map, color-primary);
        color: map-get($map, color-white);
        min-height: 0 !important;
        @media screen and (max-width: map-get($map, screen-sm)) {
          line-height: 30px;
          margin: map-get($map, spacing-half) 0;
        }
      }
      @media screen and (max-width: map-get($map, screen-sm)) {
        margin: 0 0 map-get($map, spacing-half);
      }
    }
  }
}
