@import "../../../../../resources/styles/themes/themify";
@include themify {
  .link {
    background-color: map-get($map, color-primary);
    border-bottom: 3px solid transparent;
    color: map-get($map, color-white);
    display: flex;
    line-height: 42px;
    padding: map-get($map, spacing) map-get($map, spacing) 17px;
    text-decoration: none;
    @media screen and (max-width: map-get($map, screen-md)) and (min-width: (map-get($map, screen-sm) + 1)) {
      padding: map-get($map, spacing) 12px 17px;
    }
    @media screen and (max-width: map-get($map, screen-sm)) {
      flex: 1;
      line-height: 30px;
      padding: map-get($map, spacing-half) map-get($map, spacing);
    }
    &--active {
      border-bottom-color: map-get($map, color-primary-lighter);
      color: map-get($map, color-primary-lighter);
      @media screen and (max-width: map-get($map, screen-sm)) {
        border-bottom: none;
        color: map-get($map, color-primary-lighter);
      }
    }
  }
}
