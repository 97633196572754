@import "../../../../../../resources/styles/common/mixins";
@import "../../../../../../resources/styles/themes/themify";
@include themify {
  .transactions {
    &-title {
      color: map-get($map, font-secondary-color);
      font-size: 24px;
      padding: map-get($map, spacing-double) 0 0;
      @media screen and (max-width: map-get($map, screen-md)) {
        padding-bottom: map-get($map, spacing-half);
      }
      @media screen and (max-width: map-get($map, screen-sm)) {
        align-items: center;
        display: flex;
        font-size: map-get($map, font-size-lg);
        justify-content: space-between;
        padding-top: map-get($map, spacing);
      }
    }
    &-table {
      @include table;
      @media screen and (max-width: map-get($map, screen-md)) {
        margin-top: map-get($map, spacing-half);
      }
      &__thead {
        @include table-thead;
      }
      &__row {
        @include table-row;
      }
      &__cell {
        @include table-cell;
        &--in {
          @include transaction-direction(map-get($map, color-success), 90deg);
        }
        &--out {
          @include transaction-direction(map-get($map, color-error), -90deg);
        }
      }
    }
  }
}
