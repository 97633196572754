@import "../../../../resources/styles/themes/themify";
@include themify {
  .address {
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    justify-content: center;
    text-align: center;
    word-break: break-all;
    @media screen and (max-width: map-get($map, screen-sm)) {
      font-size: map-get($map, font-size-lg);
    }
    @media screen and (max-width: map-get($map, screen-xs)) {
      padding-bottom: map-get($map, spacing-half);
    }
    &__char {
      background-color: map-get($map, page-secondary-hover-color);
      color: map-get($map, font-secondary-color);
      font-weight: map-get($map, font-weight-strong);
      max-width: 100%;
      padding: 3px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
