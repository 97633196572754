@import "../../../../../resources/styles/common/mixins";
@import "../../../../../resources/styles/themes/themify";
@include themify {
  .mobile {
    background-color: map-get($map, color-primary);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      padding: map-get($map, spacing-half) map-get($map, spacing);
    }
    &__links {
      @include mobile-links;

      max-height: 0;
      &--active {
        @include mobile-links;

        border-bottom: 1px solid map-get($map, color-gray-light);
        height: auto;
        transition: none;
      }
    }
    &__menu-icon {
      @include mobile-menu-logo(map-get($map, color-white));
      &--active {
        @include mobile-menu-logo(map-get($map, color-white));
      }
    }
  }
}
