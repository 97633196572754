@import "../../../resources/styles/common/mixins";
@import "../../../resources/styles/themes/themify";
@include themify {
  .wallet-export {
    @include mobile {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: map-get($map, spacing-double);
      @media screen and (max-width: map-get($map, screen-sm)) {
        padding: map-get($map, spacing);
      }
    }
    &__title {
      @include mobile {
        font-size: map-get($map, font-size-lg);
      }

      color: map-get($map, font-tertiary-color);
      text-align: center;
    }
    &__subtitle {
      @include mobile {
        font-size: map-get($map, font-size-sm);
      }

      color: map-get($map, font-tertiary-color);
      text-align: center;
    }
    &__form {
      margin-top: map-get($map, spacing-double);
    }
    &__actions {
      justify-content: space-between;
      margin: map-get($map, spacing) 0 0;
      button {
        text-transform: capitalize;
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
    &__link {
      @include mobile {
        font-size: map-get($map, font-size-sm);
      }

      color: map-get($map, color-primary);
      font-size: map-get($map, font-size);
      text-decoration: none;
      text-transform: lowercase;
      &:hover {
        color: map-get($map, color-primary-dark);
        text-decoration: underline;
      }
    }
  }
}
