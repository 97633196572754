@font-face {
  font-family: $font-family-btcx-protected-web;
  font-style: normal;
  font-weight: normal;
  src: url("./../../fonts/btcx-protected-web.ttf") format("truetype"),
    url("./../../fonts/btcx-protected-web.woff") format("woff"),
    url("./../../fonts/btcx-protected-web.svg") format("svg");
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
body {
  font-family: $font-family-primary;
  margin: 0;
  overflow-x: hidden;
}
@page {
  margin: 20px;
}
fieldset {
  border: none;
  padding: 0;
}
