@import "../../../resources/styles/themes/themify";
@include themify {
  .newsletter {
    margin-top: 30px;
    &__heading {
      color: map-get($map, action-icon-color);
      font-size: map-get($map, font-size-sm);
      font-weight: map-get($map, font-weight-default);
      line-height: 28px;
    }
    &__form {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 90%;
    }
    &__form-control {
      position: relative;
    }
    & &__btn {
      border-radius: 0 4px 4px 0;
      box-shadow: none;
      margin-left: -55px;
      min-height: 40px;
      min-width: auto;
      width: 50px;
      &:hover {
        background-color: map-get($map, color-primary-dark)!important;
      }
      &[disabled] {
        background-color: map-get($map, color-primary);
        color: map-get($map, color-white);
        cursor: not-allowed;
        pointer-events: all;
      }
    }
    &__input {
      align-items: center;
      font-family: map-get($map, font-family-primary);
      min-width: 200px;
      input {
        background-color: map-get($map, color-white);
        border-radius: 4px 0 0 4px;
        box-shadow: inset 0 0 0 20px map-get($map, color-white);
        color: map-get($map, footer-bg-color);
        font-size: map-get($map, font-size-sm);
        height: 40px;
        margin-right: 50px;
        max-width: 120px;
        padding: 0 10px;
        &::placeholder {
          font-size: map-get($map, font-size-xs);
        }
      }
      & > div {
        &::after,
        &::before {
          border: 0 !important;
        }
      }
    }
  }
}
